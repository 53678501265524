
import { Invoice, InvoiceInput, Project } from '@/api/interfaces'
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { isIdentifiable } from '@/api/interfaces/identifiable'
import DateInput from '@/components/common/date-input.vue'
import CurrencyInput from '@/components/common/currency-input.vue'
import { useProjectRepository } from '@/api/repository/project-repository'
import { useInvoiceRepository } from '@/api/repository/invoice-repository'
import { uniqueId, debounce } from 'lodash-es'
import { useValidation, ValidationForm } from '@/utils/validation'
import { usePermissions } from '@/composables/permissions'
import { useOrderRepository } from '@/api/repository/order-repository'

export default defineComponent({
  components: {
    CurrencyInput,
    DateInput,
  },
  inheritAttrs: false,
  props: {
    item: {
      type: Object as PropType<InvoiceInput | Invoice>,
      required: true,
    },
    project: {
      type: Object as PropType<Project>,
      default: undefined,
    },
  },
  emits: ['click:save', 'click:cancel'],
  setup(props, { emit, attrs }) {
    const form = ref<ValidationForm | null>(null)
    const formId = uniqueId('form-')

    const { canConfirmInvoice, canCreateInvoiceForProject } = usePermissions()

    const invoiceRepository = useInvoiceRepository()
    const itemInternal = ref<InvoiceInput | Invoice>(invoiceRepository.dummyInvoiceInput())
    watch(props.item, (value) => (itemInternal.value = value))

    const projectRepository = useProjectRepository()
    const { getAll: getAllProjects, data: projects, isLoading: projectsLoading } = projectRepository.useGetAll()

    const projectSearchInput = ref('')
    // TODO: better filter for smaller size
    const debounceUserSearch = debounce(
      (name) => getAllProjects({ params: { name, size: 20000, projectStatus: 'ACTIVE' } }),
      500
    )
    watch(projectSearchInput, (name) => debounceUserSearch(name))
    const projectsFiltered = computed(() => projects.value.filter((project) => canCreateInvoiceForProject(project)))

    const orderRepository = useOrderRepository()

    const { getAll: getAllOrders, data: orders, isLoading: ordersLoading } = orderRepository.useGetAll()
    const orderSearchInput = ref('')
    const debounceOrderSearch = debounce(
      (name) => getAllOrders({ params: { name, projectIds: itemInternal.value.projectId, size: 9999 } }),
      500
    )
    watch([orderSearchInput, itemInternal], ([name]) => debounceOrderSearch(name), { deep: true })

    function onCancel() {
      emit('input', false)
      emit('click:cancel')
    }

    function onSubmit() {
      if (!form.value?.validate()) return
      emit('input', false)
      emit('click:save', { ...itemInternal.value })
    }

    const validation = useValidation()

    watch(
      () => attrs.value,
      () => {
        itemInternal.value = { ...props.item }
        if (props.project) itemInternal.value.projectId = props.project.id
        form.value?.resetValidation()
      }
    )

    return {
      form,
      formId,

      isIdentifiable,

      itemInternal,

      canConfirmInvoice,

      projectsFiltered,
      projectSearchInput,
      projectsLoading,

      orderSearchInput,
      orders,
      ordersLoading,

      onCancel,
      onSubmit,

      validation,
    }
  },
})
