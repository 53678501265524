
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiPlus, mdiCheck, mdiClose, mdiPencil, mdiDelete } from '@mdi/js'
import { usePagination } from '@/utils/pagination'
import invoiceTable from '@/components/invoice/invoice-table.cmp.vue'
import InvoiceDialog from '@/components/invoice/invoice-dialog.cmp.vue'
import CommonAutocomplete from '@/components/common/common-autocomplete.vue'
import { useProjectRepository } from '@/api/repository/project-repository'
import { useInvoiceRepository } from '@/api/repository/invoice-repository'
import { debounce } from 'lodash-es'
import { Invoice, InvoiceInput } from '@/api/interfaces'
import { useGetUsers } from '@/api/users'
import { usePermissions } from '@/composables/permissions'
import { Rights } from '@/api/rights'
import { useAuthGetters, useNotify } from '@/store'

export default defineComponent({
  name: 'invoice-overview',
  components: {
    InvoiceDialog,
    invoiceTable,
    CommonAutocomplete,
  },
  setup(_, { root }) {
    const { vuetifyTableOptions, paginationParams } = usePagination()
    vuetifyTableOptions.value.sortBy = ['id']
    vuetifyTableOptions.value.sortDesc = [true]

    const filters: { userId?: number; projectId?: number; dateFrom?: string; dateTo?: string } = reactive({
      userId: undefined,
      projectId: undefined,
      dateFrom: undefined,
      dateTo: undefined,
    })

    const { addNotification } = useNotify()

    const activeYear = ref(new Date().getFullYear())
    setYear(activeYear.value)
    const years = computed(() => {
      const years: { text: number; value: number }[] = []
      const currentYear = new Date().getFullYear()
      for (let i = 2017; i <= currentYear; i++) {
        years.push({ text: i, value: i })
      }
      return years
    })

    const { hasRight, currentUser } = useAuthGetters()

    const editIsActive = ref(false)

    const projectManagerIds = computed(() =>
      hasRight.value(Rights.ORDER_CREATE_ALL) ? undefined : [currentUser.value.id]
    )

    const { getUsers: getUsersRequest, data: users } = useGetUsers()

    const invoiceRepository = useInvoiceRepository()
    const { getAll: getAllInvoices, data: invoices, isLoading, total: totalInvoices } = invoiceRepository.useGetAll()
    function fetchInvoices() {
      getAllInvoices({ params: { ...paginationParams.value, ...filters } })
    }

    const projectRepository = useProjectRepository()
    const {
      getAll: getAllProjects,
      data: projects,
      paginationResponse: projectPaginationResponse,
    } = projectRepository.useGetAll()
    const projectSearchInput = ref('')
    const debounceProjectSearch = debounce(
      (val) => getAllProjects({ params: { name: val, projectManagerIds: projectManagerIds.value, size: 15 } }),
      500
    )
    watch(projectSearchInput, (name) => debounceProjectSearch(name))

    const itemToEdit = ref(invoiceRepository.dummyInvoiceInput())

    const { canCreateInvoices } = usePermissions()

    function setYear(year?: number) {
      if (year === null) {
        filters.dateFrom = undefined
        filters.dateTo = undefined
      } else {
        filters.dateFrom = `${year}-01-01`
        filters.dateTo = `${year}-12-31`
      }
    }
    async function init() {
      try {
        await getUsersRequest({ page: 0, size: 15 })
      } catch (error: any) {
        error.userMessage = root.$t('users.get.error')

        throw error
      }
    }
    init()

    watch(activeYear, (activeYear) => {
      setYear(activeYear)
    })

    watch(filters, () => {
      fetchInvoices()
    })

    async function onSave(item: InvoiceInput) {
      const { save } = invoiceRepository.useSave()
      await save(item)
      fetchInvoices()
      addNotification({ text: root.$t('form.save.success').toString(), type: 'success' })
    }

    async function onDelete(item: Invoice) {
      const { remove } = invoiceRepository.useRemove()
      await remove(item.id)
      fetchInvoices()
      addNotification({ text: root.$t('form.delete.success').toString(), type: 'success' })
    }

    const filterPageCounter = ref<number>(1)

    function loadProjects() {
      if (filterPageCounter.value <= projectPaginationResponse.value.totalPages) {
        getAllProjects({ params: { page: filterPageCounter.value++, size: 15 } })
      }
    }

    function resetFilterPageCounter() {
      filterPageCounter.value = 1
    }

    return {
      filters,
      activeYear,
      years,
      icons: { mdiPlus, mdiCheck, mdiClose, mdiPencil, mdiDelete },
      canCreateInvoices,
      vuetifyTableOptions,
      editIsActive,
      itemToEdit,

      users,
      projects,
      projectSearchInput,

      fetchInvoices,
      invoices,
      isLoading,
      totalInvoices,
      onSave,
      onDelete,
      loadProjects,
      resetFilterPageCounter,
    }
  },
})
