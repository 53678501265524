
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { DataTableHeader } from 'vuetify'
import { mdiPlus, mdiDelete, mdiPencil } from '@mdi/js'
import { useFormat } from '@/utils/format'
import { Order, Project } from '@/api/interfaces'
import { useOrderRepository } from '@/api/repository/order-repository'
import OrderDialog from '@/components/order/order-dialog.vue'
import ConfirmDialog from '@/components/common/confirm-dialog.vue'

export default defineComponent({
  name: 'order-table',
  components: {
    OrderDialog,
    ConfirmDialog,
  },
  props: {
    items: {
      type: Array as PropType<Order[]>,
      required: true,
    },
    create: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Function as PropType<(order: Order) => boolean>,
      default: () => false,
    },
    deletable: {
      type: Function as PropType<(order: Order) => boolean>,
      default: () => false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    defaultProject: {
      type: Object as PropType<Project | null>,
      default: null,
    },
    hideProject: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, emit }) {
    const { formatCurrency, formatDateString } = useFormat()
    const { dummyOrderInput } = useOrderRepository()

    const showEdit = ref(false)
    const itemToEdit = ref(dummyOrderInput())
    const showDelete = ref(false)
    const itemToDelete = ref<Order | undefined>()

    const tableHeaders = computed<DataTableHeader[]>(() => {
      const headers = [
        {
          text: root.$t('form.field.id').toString(),
          value: 'id',
          width: '80px',
        },
        {
          text: root.$t('form.field.name').toString(),
          value: 'name',
          width: '150px',
        },
        {
          text: root.$t('form.field.orderNumber').toString(),
          value: 'orderNumber',
          width: '150px',
        },
        {
          text: root.$t('form.field.date').toString(),
          value: 'date',
          width: '150px',
        },
        {
          text: root.$t('form.field.budgetNetto').toString(),
          value: 'netAmount',
          width: '150px',
        },
        {
          text: root.$t('form.field.usedBudget').toString(),
          value: 'invoicesNetto',
          width: '200px',
        },
        {
          text: root.$t('form.field.inPercent').toString(),
          value: 'inPercent',
          width: '120px',
        },
        {
          text: root.$t('form.field.availableBudget').toString(),
          value: 'availableBudget',
          width: '200px',
        },
        {
          text: root.$t('form.field.comment').toString(),
          value: 'comment',
          width: '200px',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '80px',
        },
      ]

      if (!props.hideProject) {
        headers.splice(1, 0, {
          text: root.$t('form.field.project').toString(),
          value: 'projectName',
          width: '150px',
        })
      }

      return headers
    })

    async function clickAdd() {
      emit('click:add')
      itemToEdit.value = { ...dummyOrderInput() }
      showEdit.value = true
    }

    async function clickEdit(item: Order) {
      emit('click:edit', { ...item })
      itemToEdit.value = { ...item }
      showEdit.value = true
    }

    async function clickDelete(item: Order) {
      itemToDelete.value = { ...item }
      showDelete.value = true
      emit('click:delete', { ...item })
    }

    async function onDeleteConfirm() {
      if (!itemToDelete.value) return
      showDelete.value = false
      emit('delete', { ...itemToDelete.value })
    }

    return {
      tableHeaders,
      itemToEdit,
      itemToDelete,
      showEdit,
      showDelete,
      formatDateString,
      formatCurrency,
      clickAdd,
      clickEdit,
      clickDelete,
      onDeleteConfirm,
      icons: { mdiPlus, mdiPencil, mdiDelete },
    }
  },
})
