export function formatEmailToFullName(email: string) {
  if (!email) return
  const fullName: string[] = []
  const nameArr = email.split('@')[0].split('.')
  nameArr.map((str) => {
    const [firstLetter, ...rest] = str
    fullName.push(`${firstLetter.toUpperCase()}${rest.join('')}`)
  })
  return fullName.length === 2 ? `${fullName[0]} ${fullName[1]}` : `${fullName[0]}`
}
