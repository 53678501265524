import { repository } from '@/plugins/repository'
import { Invoice, InvoiceInput } from '../interfaces'

export const useInvoiceRepository = () => {
  const version = 'v1'
  const resource = 'invoices'
  const invoiceRepository = repository<Invoice, InvoiceInput>(version, resource)

  const dummyInvoiceInput = (): InvoiceInput => ({
    comment: '',
    date: new Date().toLocaleDateString('en-CA'),
    grossAmount: 0,
    netAmount: 0,
    projectId: 0,
    clerk: '',
    datePayment: null,
    paidAmount: 0,
    paidAmountNet: 0,
    orderId: null,
  })

  const dummyInvoice = (): Invoice => ({
    id: 0,
    comment: '',
    date: new Date().toLocaleDateString('en-CA'),
    grossAmount: 0,
    netAmount: 0,
    projectId: 0,
    clerk: '',
    datePayment: null,
    paidAmount: 0,
    paidAmountNet: 0,
    orderName: '',
    projectName: '',
    orderId: null,
  })

  return {
    ...invoiceRepository,
    dummyInvoiceInput,
    dummyInvoice,
  }
}
