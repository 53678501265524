
import { computed, defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api'
import { usePagination } from '@/utils/pagination'
import { useGetUsersBasic, User } from '@/api/users'
import { useAuthGetters, useNotify } from '@/store'
import { Rights } from '@/api/rights'
import { mdiPlus } from '@mdi/js'
import ActivityTable from '@/components/activity/activity-table.cmp.vue'
import ActivityDialog from '@/components/activity/activity-dialog.cmp.vue'
import CommonAutocomplete from '@/components/common/common-autocomplete.vue'
import { useActivityRepository } from '@/api/repository/activity-repository'
import { Activity, ActivityInput } from '@/api/interfaces'
import { useProjectRepository } from '@/api/repository/project-repository'
import { usePlanRepository } from '@/api/repository/plan-repository'
import { debounce } from 'lodash-es'

export default defineComponent({
  name: 'activity-overview',
  components: {
    ActivityTable,
    ActivityDialog,
    CommonAutocomplete,
  },
  setup(_, { root }) {
    const { addNotification } = useNotify()
    const { vuetifyTableOptions, paginationParams } = usePagination()
    vuetifyTableOptions.value.sortBy = ['id']
    vuetifyTableOptions.value.sortDesc = [true]

    const activityRepository = useActivityRepository()
    const { getAll: getAllActivities, data: activities, isLoading, total } = activityRepository.useGetAll()

    const { hasRight, projectsAsManager, isProjectManager, currentUser } = useAuthGetters()
    const filters: {
      userId?: number
      planId?: number
      projectIds?: number[]
      dateFrom?: string
      dateTo?: string
    } = reactive({
      userId: undefined,
      planId: undefined,
      projectIds: undefined,
      dateFrom: undefined,
      dateTo: undefined,
    })

    const activeYear = ref(new Date().getFullYear())
    setYear(activeYear.value)
    const years = computed(() => {
      const years: { text: number; value: number }[] = []
      const currentYear = new Date().getFullYear()
      for (let i = 2017; i <= currentYear; i++) {
        years.push({ text: i, value: i })
      }
      return years
    })

    const { getUsersBasic, data: usersBasic, paginationResponse: usersBasicPaginationResponse } = useGetUsersBasic()

    function setYear(year?: number) {
      if (year === null) {
        filters.dateFrom = undefined
        filters.dateTo = undefined
      } else {
        filters.dateFrom = `${year}-01-01`
        filters.dateTo = `${year}-12-31`
      }
    }

    async function updateActivities() {
      getAllActivities({ params: { ...paginationParams.value, ...filters } })
    }

    // dummy right check until freelancer permisisons are clear
    const canFilter = computed(() => hasRight.value(Rights.PROJECT_READ))

    const showUserFilter = computed(
      () => hasRight.value(Rights.USER_READ) && (hasRight.value(Rights.PLAN_READ_ALL) || isProjectManager.value)
    )

    const canCreateActivity = computed(() => hasRight.value(Rights.ACTIVITY_CREATE) || isProjectManager.value)

    function canUpdateActivity(activity: Activity) {
      return (
        hasRight.value(Rights.ACTIVITY_UPDATE_ALL) ||
        projectsAsManager.value.includes(activity.projectId) ||
        currentUser.value.id === activity.userId
      )
    }

    function canDeleteActivity(activity: Activity) {
      return (
        hasRight.value(Rights.ACTIVITY_DELETE_ALL) ||
        projectsAsManager.value.includes(activity.projectId) ||
        currentUser.value.id === activity.userId
      )
    }

    const projectRepository = useProjectRepository()
    const {
      getAll: getAllProjects,
      data: projects,
      paginationResponse: projectPaginationResponse,
    } = projectRepository.useGetAll()

    const planRepository = usePlanRepository()
    const { getAll: getAllPlans, data: plans } = planRepository.useGetAll()

    const editIsActive = ref(false)
    const activityToEdit = ref<ActivityInput>({ ...activityRepository.dummyActivityInput() })

    async function onSaveActivity(activity: Activity) {
      const { save } = activityRepository.useSave()
      await save(activity)
      addNotification({ text: root.$t('form.save.success').toString(), type: 'success' })
      updateActivities()
    }

    async function onDeleteActivity(activity: Activity) {
      const { remove } = activityRepository.useRemove()
      await remove(activity.id)
      addNotification({ text: root.$t('form.delete.success').toString(), type: 'success' })
      updateActivities()
    }

    watch(filters, () => {
      updateActivities()
    })

    watch(activeYear, (activeYear) => {
      setYear(activeYear)
    })

    const projectSearchInput = ref('')
    const debounceProjectSearch = debounce((name) => getAllProjects({ params: { name, size: 15 } }), 500)
    watch(projectSearchInput, (name) => debounceProjectSearch(name))

    const userSearchInput = ref('')
    const debounceUserSearch = debounce((name) => getUsersBasic({ page: 0, size: 15 }, { name }), 500)
    watch(userSearchInput, (name) => debounceUserSearch(name))
    const getUserFullName = (user: User) => {
      return `${user.firstName} ${user.lastName}`
    }

    onMounted(async () => {
      if (!canFilter.value) return
      getUsersBasic({ size: 15, page: 0 })
      getAllProjects({ params: { size: 15 } })
      getAllPlans({ params: { size: 2000 } })
    })

    const filterPageCounter = ref<number>(1)

    function loadProjects() {
      if (filterPageCounter.value <= projectPaginationResponse.value.totalPages) {
        getAllProjects({ params: { page: filterPageCounter.value++, size: 15 } })
      }
    }

    function loadUser() {
      if (filterPageCounter.value <= usersBasicPaginationResponse.value.totalPages) {
        getUsersBasic({ page: filterPageCounter.value++, size: 15 })
      }
    }

    function resetFilterPageCounter() {
      filterPageCounter.value = 1
    }

    return {
      activities,
      showUserFilter,
      isLoading,
      total,
      canFilter,
      updateActivities,
      canCreateActivity,
      canUpdateActivity,
      canDeleteActivity,
      onSaveActivity,
      onDeleteActivity,
      filters,
      usersBasic,
      plans,
      projects,
      activeYear,
      years,
      editIsActive,
      activityToEdit,
      vuetifyTableOptions,
      icons: { mdiPlus },
      projectSearchInput,
      userSearchInput,
      getUserFullName,
      loadProjects,
      loadUser,
      resetFilterPageCounter,
    }
  },
})
