import { Plan } from '@/api/interfaces'
import { i18n } from '@/plugins/i18n'

export function formatPlan(plan: Plan) {
  return `${plan.projectName} (${plan.hoursBudget - plan.bookedHours}${i18n.t('units.time.hour')}/${
    plan.hoursBudget
  }${i18n.t('units.time.hour')})`
}

export function useFormatPlan() {
  return {
    formatPlan,
  }
}
