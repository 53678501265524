
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { DataTableHeader } from 'vuetify'
import { mdiPencil, mdiDelete } from '@mdi/js'
import { Client } from '@/api/interfaces'
import { useClientRepository } from '@/api/repository/client-repository'
import ClientDialog from '@/components/client/client-dialog.cmp.vue'
import ConfirmDialog from '@/components/common/confirm-dialog.vue'

export default defineComponent({
  name: 'client-table',
  components: {
    ClientDialog,
    ConfirmDialog,
  },
  props: {
    items: {
      type: Array as PropType<Client[]>,
      required: true,
    },
    create: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Function as PropType<(client: Client) => boolean>,
      default: () => false,
    },
    deletable: {
      type: Function as PropType<(client: Client) => boolean>,
      default: () => false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, emit }) {
    const clientRepository = useClientRepository()

    const showEdit = ref(false)
    const itemToEdit = ref(clientRepository.dummyClientInput())
    const showDelete = ref(false)
    const itemToDelete = ref<Client | undefined>()

    const tableHeaders: DataTableHeader[] = [
      {
        text: root.$t('form.field.id').toString(),
        value: 'id',
        width: '80px',
      },
      {
        text: root.$t('form.field.name').toString(),
        value: 'name',
        width: '150px',
      },
      {
        text: root.$t('form.field.address').toString(),
        value: 'address',
        width: '200px',
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        width: '80px',
        align: 'end',
      },
    ]

    async function clickAdd() {
      emit('click:add')
      itemToEdit.value = { ...clientRepository.dummyClientInput() }
      showEdit.value = true
    }

    async function clickEdit(item: Client) {
      emit('click:edit', { ...item })
      itemToEdit.value = { ...item }
      showEdit.value = true
    }

    async function clickDelete(item: Client) {
      itemToDelete.value = { ...item }
      showDelete.value = true
      emit('click:delete', { ...item })
    }

    async function onDeleteConfirm() {
      if (!itemToDelete.value) return
      showDelete.value = false
      emit('delete', { ...itemToDelete.value })
    }

    return {
      icons: { mdiPencil, mdiDelete },
      showEdit,
      itemToEdit,
      showDelete,
      itemToDelete,
      clickAdd,
      clickEdit,
      clickDelete,
      onDeleteConfirm,
      tableHeaders,
    }
  },
})
