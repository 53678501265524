
import { defineComponent, PropType, ref, watch, computed } from '@vue/composition-api'
import { DataTableHeader } from 'vuetify'
import { mdiPencil, mdiDelete, mdiPlus } from '@mdi/js'
import { Invoice, InvoiceInput, Project } from '@/api/interfaces'
import { User } from '@/api/users'
import { useFormat } from '@/utils/format'
import { formatEmailToFullName } from '@/utils/format/format-email-to-full-name'
import BaseTable from '@/components/common/base-table.cmp.vue'
import InvoiceDialog from '@/components/invoice/invoice-dialog.cmp.vue'
import { useInvoiceRepository } from '@/api/repository/invoice-repository'

export default defineComponent({
  name: 'invoice-table',
  components: {
    BaseTable,
    InvoiceDialog,
  },
  extends: BaseTable,
  inheritAttrs: false,
  props: {
    items: {
      type: Array as PropType<Invoice[]>,
      required: true,
    },
    create: {
      type: Boolean,
      default: false,
    },
    defaultProject: {
      type: Object as PropType<Project | null>,
      default: null,
    },
    visibleProject: {
      type: Boolean,
      default: true,
    },
    users: {
      type: Array as PropType<User[]>,
      required: true,
    },
  },
  setup(props, { root, emit }) {
    const { formatCurrency, formatDateString } = useFormat()

    const invoices = ref<Invoice[]>([])

    watch(
      () => props.items,
      (items) => {
        invoices.value = items
      }
    )
    const filteredTableHeaders = computed(() =>
      props.visibleProject === true ? tableHeaders : tableHeaders.filter((v) => v.value !== 'projectName')
    )

    const tableHeaders: DataTableHeader[] = [
      {
        text: root.$t('form.field.id').toString(),
        value: 'id',
        width: '80px',
      },
      {
        text: root.$t('form.field.project').toString(),
        value: 'projectName',
        sortable: false,
        width: '150px',
      },
      {
        text: root.$t('form.field.date').toString(),
        value: 'date',
        width: '150px',
      },
      {
        text: root.$t('form.field.grossAmount').toString(),
        value: 'grossAmount',
        width: '150px',
      },
      {
        text: root.$t('form.field.netAmount').toString(),
        value: 'netAmount',
        width: '150px',
      },
      {
        text: root.$t('form.field.order').toString(),
        value: 'orderName',
        sortable: false,
        width: '150px',
      },
      {
        text: root.$t('form.field.datePayment').toString(),
        value: 'datePayment',
        width: '150px',
      },
      {
        text: root.$t('form.field.paidAmount').toString(),
        value: 'paidAmount',
        width: '150px',
      },
      {
        text: root.$t('form.field.paidAmountNet').toString(),
        value: 'paidAmountNet',
        width: '150px',
      },
      {
        text: root.$t('form.field.clerk').toString(),
        value: 'clerk',
        width: '150px',
      },
      {
        text: root.$t('form.field.comment').toString(),
        value: 'comment',
        width: '150px',
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        width: '80px',
        align: 'end',
      },
    ]

    const { dummyInvoiceInput } = useInvoiceRepository()

    const showEdit = ref(false)
    const itemToEdit = ref<InvoiceInput>(dummyInvoiceInput())

    function onEdit(item: InvoiceInput) {
      showEdit.value = true
      itemToEdit.value = item
    }

    async function clickAdd() {
      emit('click:add')
      itemToEdit.value = { ...dummyInvoiceInput() }
      showEdit.value = true
    }

    return {
      icons: { mdiPlus, mdiPencil, mdiDelete },
      invoices,
      formatDateString,
      formatCurrency,
      filteredTableHeaders,
      formatEmailToFullName,
      showEdit,
      itemToEdit,
      onEdit,
      clickAdd,
    }
  },
})
