var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTable',_vm._g(_vm._b({attrs:{"headers":_vm.filteredTableHeaders,"items":_vm.items,"hoverable":""},on:{"update:options":function (options) { return _vm.$emit('update:options', options); },"click:edit":_vm.onEdit,"click:confirm":_vm.onDelete},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.create)?_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$tc('invoice.title', 2))+" ")]),_c('v-spacer'),(_vm.create)?_c('v-btn',{attrs:{"outlined":"","elevation":"0","color":"secondary"},on:{"click":_vm.clickAdd}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t('form.action.add'))+" ")])],1):_vm._e()],1):_vm._e(),_c('InvoiceDialog',_vm._g({attrs:{"item":_vm.itemToEdit,"project":_vm.defaultProject},model:{value:(_vm.showEdit),callback:function ($$v) {_vm.showEdit=$$v},expression:"showEdit"}},_vm.$listeners))]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateString(item.date))+" ")]}},{key:"item.grossAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.grossAmount))+" ")]}},{key:"item.netAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.netAmount))+" ")]}},{key:"item.datePayment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateString(item.datePayment))+" ")]}},{key:"item.paidAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.paidAmount))+" ")]}},{key:"item.paidAmountNet",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.paidAmountNet))+" ")]}},{key:"item.clerk",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatEmailToFullName(item.clerk))+" ")]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [(item.comment !== null && item.comment.length > 99)?_c('td',[_vm._v(_vm._s(item.comment.substr(0, 100))+"... ")]):_c('td',[_vm._v(_vm._s(item.comment))])]}}],null,true)},'BaseTable',_vm.$props,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }