
import { Order, Project } from '@/api/interfaces'
import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import OrderForm from '@/components/order/order-form.vue'
import { isIdentifiable } from '@/api/interfaces/identifiable'
import { useProjectRepository } from '@/api/repository/project-repository'

export default defineComponent({
  name: 'order-dialog',
  components: {
    OrderForm,
  },
  props: {
    item: {
      type: Object as PropType<Order>,
      required: true,
    },
    project: {
      type: Object as PropType<Project>,
      default: undefined,
    },
  },
  setup(props, { attrs, emit }) {
    const itemInternal = ref<Order>({ ...props.item })
    const isValid = ref(false)

    const projectRepository = useProjectRepository()
    const { getAll: getAllProjects, data: projects } = projectRepository.useGetAll()

    function clickClose() {
      emit('input', false)
    }

    function clickSave() {
      emit('save', { ...itemInternal.value })
      emit('input', false)
    }

    watch(
      () => props.item,
      (value) => (itemInternal.value = value)
    )

    watch(
      () => props.project,
      (value) => (itemInternal.value.projectId = value.id)
    )

    watch(
      () => attrs.value,
      (value) => {
        if (!value) return
        getAllProjects({ params: { size: 2000, projectStatus: 'ACTIVE' } })
        itemInternal.value = { ...props.item }
        if (props.project) itemInternal.value.projectId = props.project.id
        // form.value?.resetValidation()
      }
    )

    return {
      itemInternal,
      projects,
      isValid,
      clickClose,
      clickSave,
      isIdentifiable,
    }
  },
})
