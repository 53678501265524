
import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { required, ValidationForm } from '@/utils/validation'
import { Client } from '@/api/interfaces'

export default defineComponent({
  name: 'client-dialog',
  props: {
    item: {
      type: Object as PropType<Client>,
      required: true,
    },
  },
  setup: (props, { emit, attrs }) => {
    const form = ref<ValidationForm | null>(null)
    const formIsValid = ref(false)
    const client = ref<Client>({ ...props.item })

    function onSubmit() {
      emit('save', { ...client.value })
      emit('input', false)
    }

    function clickCancel() {
      emit('input', false)
    }

    watch(
      () => props.item,
      (value) => {
        client.value = { ...value }
      }
    )

    watch(
      () => attrs.value,
      (value) => {
        if (!value) return
        client.value = { ...props.item }
        form.value?.resetValidation()
      }
    )

    return {
      form,
      formIsValid,
      client,
      onSubmit,
      clickCancel,
      required,
    }
  },
})
