import { Project } from '@/api/interfaces'
import { Rights } from '@/api/rights'
import { useAuthGetters } from '@/store'
import { computed } from '@vue/composition-api'

const { hasRight, isProjectManager, currentUser } = useAuthGetters()

const canConfirmInvoice = computed(() => hasRight.value(Rights.INVOICE_CONFIRM))

const canReadInvoices = computed(
  () => hasRight.value(Rights.INVOICE_READ_ALL) || (hasRight.value(Rights.INVOICE_READ_OWN) && isProjectManager.value)
)

const canCreateInvoices = computed(
  () =>
    hasRight.value(Rights.INVOICE_CREATE_ALL) || (hasRight.value(Rights.INVOICE_CREATE_OWN) && isProjectManager.value)
)

function canCreateInvoiceForProject(project: Project) {
  return hasRight.value(Rights.INVOICE_CREATE_ALL) || project.projectManagerId === currentUser.value.id
}

function canCreateOrderForProject(project: Project) {
  return hasRight.value(Rights.ORDER_CREATE_ALL) || project.projectManagerId === currentUser.value.id && hasRight.value(Rights.ORDER_CREATE_OWN)
}

const canReadMonthlyHours = computed(
  () => hasRight.value(Rights.MONTHLY_HOURS_READ_OWN) || hasRight.value(Rights.MONTHLY_HOURS_READ_ALL)
)

const canReadOrder = computed(
  () => hasRight.value(Rights.ORDER_READ_ALL) || (hasRight.value(Rights.ORDER_READ_OWN) && isProjectManager.value)
)

const canCreateOrder = computed(
  () => hasRight.value(Rights.ORDER_CREATE_ALL) || (hasRight.value(Rights.ORDER_CREATE_OWN) && isProjectManager.value)
)

const canUpdateOrder = computed(
  () => hasRight.value(Rights.ORDER_UPDATE_ALL) || (hasRight.value(Rights.ORDER_UPDATE_OWN) && isProjectManager.value)
)

const canDeleteOrder = computed(
  () => hasRight.value(Rights.ORDER_DELETE_ALL) || (hasRight.value(Rights.ORDER_DELETE_OWN) && isProjectManager.value)
)

export function usePermissions() {
  return {
    canConfirmInvoice,
    canReadInvoices,
    canCreateInvoices,
    canCreateInvoiceForProject,
    canCreateOrderForProject,
    canReadMonthlyHours,
    canReadOrder,
    canCreateOrder,
    canUpdateOrder,
    canDeleteOrder,
  }
}
