var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.tableHeaders,"items":_vm.items},scopedSlots:_vm._u([{key:"top",fn:function(){return [(!_vm.hideTitle || _vm.create)?_c('v-toolbar',{attrs:{"flat":""}},[(!_vm.hideTitle)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$tc('order.title', 2))+" ")]):_vm._e(),_c('v-spacer'),(_vm.create)?_c('v-btn',{attrs:{"outlined":"","elevation":"0","color":"secondary"},on:{"click":_vm.clickAdd}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t('form.action.add'))+" ")])],1):_vm._e()],1):_vm._e(),_vm._t("edit",function(){return [_c('order-dialog',_vm._g({attrs:{"item":_vm.itemToEdit,"project":_vm.defaultProject},model:{value:(_vm.showEdit),callback:function ($$v) {_vm.showEdit=$$v},expression:"showEdit"}},_vm.$listeners))]},{"item":_vm.itemToEdit}),_vm._t("delete",function(){return [_c('confirm-dialog',{on:{"confirm":_vm.onDeleteConfirm},model:{value:(_vm.showDelete),callback:function ($$v) {_vm.showDelete=$$v},expression:"showDelete"}})]},{"item":_vm.itemToDelete})]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateString(item.date))+" ")]}},{key:"item.netAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.netAmount))+" ")]}},{key:"item.invoicesNetto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.invoicesNetto))+" ")]}},{key:"item.inPercent",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"value":(item.invoicesNetto / item.netAmount) * 100,"height":"25"}},[_vm._v(" "+_vm._s(Math.ceil((item.invoicesNetto / item.netAmount) * 100))+" % ")])]}},{key:"item.availableBudget",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.netAmount - item.invoicesNetto))+" ")]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [(item.comment !== null && item.comment.length > 99)?_c('td',[_vm._v(_vm._s(item.comment.substr(0, 100))+"... ")]):_c('td',[_vm._v(_vm._s(item.comment))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.editable)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.clickEdit(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]):_vm._e(),(_vm.deletable)?_c('v-icon',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.clickDelete(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")]):_vm._e()]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }