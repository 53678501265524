
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { DataTableHeader } from 'vuetify'
import { mdiPencil, mdiDelete } from '@mdi/js'
import ConfirmDialog from '@/components/common/confirm-dialog.vue'
import { VDataTable } from 'vuetify/lib'

export default defineComponent({
  components: {
    ConfirmDialog,
  },
  extends: VDataTable,
  props: {
    headers: {
      type: Array as PropType<DataTableHeader[]>,
      default: () => [],
    },
    editable: {
      type: Function as PropType<(item: any) => boolean>,
      default: () => true,
    },
    deletable: {
      type: Function as PropType<(item: any) => boolean>,
      default: () => true,
    },
  },
  emits: ['click:edit', 'click:delete', 'click:deleteConfirm'],
  setup(props) {
    const headersComputed = computed(() => [...props.headers])

    const showConfirmDialog = ref(false)
    const deletableItem = ref<unknown | null>()
    function onDelete(item: unknown) {
      deletableItem.value = item
      showConfirmDialog.value = true
    }

    return {
      headersComputed,
      showConfirmDialog,
      deletableItem,
      onDelete,
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
})
