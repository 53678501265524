
import { defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiPlus } from '@mdi/js'
import { Rights } from '@/api/rights'
import { useAuthGetters, useNotify } from '@/store'
import { usePagination } from '@/utils/pagination'
import clientTable from '@/components/client/client-table.cmp.vue'
import clientDialog from '@/components/client/client-dialog.cmp.vue'
import { Client, ClientInput } from '@/api/interfaces'
import { useClientRepository } from '@/api/repository/client-repository'
import { debounce } from 'lodash-es'

export default defineComponent({
  name: 'client-overview',
  components: {
    clientTable,
    clientDialog,
  },
  setup(_, { root }) {
    const { vuetifyTableOptions, paginationParams } = usePagination()
    vuetifyTableOptions.value.sortBy = ['id']
    vuetifyTableOptions.value.sortDesc = [true]

    const { addNotification } = useNotify()
    const clientRepository = useClientRepository()
    const { getAll: getAllClients, data: clients, isLoading, total: totalClients } = clientRepository.useGetAll()

    const editIsActive = ref(false)
    const deleteIsActive = ref(false)
    const clientToEdit = ref<ClientInput>({ ...clientRepository.dummyClientInput() })
    const clientToDelete = ref<Client | null>(null)

    const { hasRight } = useAuthGetters()

    const filters: { id?: number } = reactive({
      id: undefined,
    })

    async function updateClients() {
      getAllClients({ params: { ...paginationParams.value, ...filters } })
    }

    async function onSaveClient(client: ClientInput) {
      const { save } = clientRepository.useSave()
      await save(client)
      addNotification({ text: root.$t('form.save.success').toString(), type: 'success' })
      updateClients()
    }

    async function onDeleteClient(client: Client) {
      const { remove } = clientRepository.useRemove()
      await remove(client.id)
      addNotification({ text: root.$t('form.delete.success').toString(), type: 'success' })
      updateClients()
    }

    const canCreateClient = hasRight.value(Rights.CLIENT_CREATE)

    function canUpdateClient() {
      return hasRight.value(Rights.CLIENT_UPDATE)
    }

    function canDeleteClient() {
      return hasRight.value(Rights.CLIENT_DELETE)
    }

    const { getAll: getAllClientSearch, data: clientSearch } = clientRepository.useGetAll()
    const clientSearchInput = ref('')
    const debounceClientSearch = debounce((name) => getAllClientSearch({ params: { name } }), 500)
    watch(clientSearchInput, (name) => debounceClientSearch(name))

    watch(filters, () => updateClients())

    return {
      filters,
      icons: { mdiPlus },
      canCreateClient,
      canUpdateClient,
      canDeleteClient,
      clients,
      isLoading,
      totalClients,
      onDeleteClient,
      deleteIsActive,
      updateClients,
      onSaveClient,
      clientToEdit,
      editIsActive,
      clientToDelete,
      vuetifyTableOptions,
      clientSearchInput,
      clientSearch,
    }
  },
})
