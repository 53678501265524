import { repository } from '@/plugins/repository'
import { Client, ClientInput } from '../interfaces'

export const useClientRepository = () => {
  const version = 'v1'
  const resource = 'clients'
  const clientRepository = repository<Client, ClientInput>(version, resource)

  const dummyClientInput = (): ClientInput => ({
    address: '',
    name: '',
  })

  const dummyClient = (): Client => ({
    address: '',
    id: 0,
    name: '',
  })

  return {
    ...clientRepository,
    dummyClientInput,
    dummyClient,
  }
}
