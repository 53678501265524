
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { DataTableHeader } from 'vuetify'
import { mdiPencil, mdiDelete } from '@mdi/js'
import { Activity, Project } from '@/api/interfaces'
import ActivityDialog from '@/components/activity/activity-dialog.cmp.vue'
import ConfirmDialog from '@/components/common/confirm-dialog.vue'
import { useActivityRepository } from '@/api/repository/activity-repository'
import { useFormat } from '@/utils/format'

export default defineComponent({
  name: 'activity-table',
  components: {
    ActivityDialog,
    ConfirmDialog,
  },
  props: {
    items: {
      type: Array as PropType<Activity[]>,
      required: true,
    },
    create: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Function as PropType<(activity: Activity) => boolean>,
      default: () => false,
    },
    deletable: {
      type: Function as PropType<(activity: Activity) => boolean>,
      default: () => false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    defaultProject: {
      type: Object as PropType<Project | null>,
      default: null,
    },
  },
  emits: ['update:item', 'click:edit'],
  setup(props, { root, emit }) {
    const { formatDateString } = useFormat()
    const { dummyActivityInput } = useActivityRepository()

    const showEdit = ref(false)
    const itemToEdit = ref(dummyActivityInput())
    const showDelete = ref(false)
    const itemToDelete = ref<Activity | undefined>()

    const tableHeaders: DataTableHeader[] = [
      {
        text: root.$t('form.field.id').toString(),
        value: 'id',
      },
      {
        text: root.$t('form.field.project').toString(),
        value: 'projectName',
        sortable: false,
      },
      {
        text: root.$t('form.field.plan').toString(),
        value: 'planId',
        sortable: false,
      },
      {
        text: root.$t('form.field.user').toString(),
        value: 'userFullName',
        sortable: false,
      },
      {
        text: root.$t('form.field.date').toString(),
        value: 'date',
      },
      {
        text: root.$t('form.field.hours').toString(),
        value: 'hours',
      },
      {
        text: root.$t('form.field.comment').toString(),
        value: 'comment',
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        width: '80px',
        align: 'end',
      },
    ]

    async function clickAdd() {
      emit('click:add')
      itemToEdit.value = { ...dummyActivityInput() }
      showEdit.value = true
    }

    async function clickEdit(item: Activity) {
      emit('click:edit', { ...item })
      itemToEdit.value = { ...item }
      showEdit.value = true
    }

    async function clickDelete(item: Activity) {
      itemToDelete.value = { ...item }
      showDelete.value = true
      emit('click:delete', { ...item })
    }

    async function onDeleteConfirm() {
      if (!itemToDelete.value) return
      showDelete.value = false
      emit('delete', { ...itemToDelete.value })
    }

    return {
      icons: { mdiPencil, mdiDelete },
      tableHeaders,
      showEdit,
      itemToEdit,
      showDelete,
      itemToDelete,
      clickAdd,
      clickEdit,
      clickDelete,
      onDeleteConfirm,
      formatDateString,
    }
  },
})
