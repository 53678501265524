
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { OrderInput, Project } from '@/api/interfaces'
import DateInput from '@/components/common/date-input.vue'
import CurrencyInput from '@/components/common/currency-input.vue'
import { useAuthGetters } from '@/store/modules/auth/useAuthModule'
import { Rights } from '@/api/rights'
import { usePermissions } from '@/composables/permissions'

export default defineComponent({
  components: {
    DateInput,
    CurrencyInput,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object as PropType<OrderInput>,
      required: true,
    },
    projects: {
      type: Array as PropType<Project[]>,
      required: true,
    },
    project: {
      type: Object as PropType<Project | null>,
      default: null,
    },
  },
  setup(props, { emit }) {
    const itemInternal = ref({ ...props.item })

    const { canCreateOrderForProject } = usePermissions()

    const filteredProjects = computed(() => props.projects.filter((project) => canCreateOrderForProject(project)))

    const showMenu = ref(false)
    const formIsValid = ref(false)

    function updateItem() {
      emit('update:item', { ...itemInternal.value })
    }

    watch(
      () => props.item,
      (value) => (itemInternal.value = value)
    )

    watch(
      () => props.project,
      (value) => (itemInternal.value.projectId = value?.id || 0)
    )

    return {
      itemInternal,
      showMenu,
      formIsValid,
      updateItem,
      filteredProjects,
    }
  },
})
