
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiPlus, mdiCheck, mdiClose, mdiPencil, mdiDelete } from '@mdi/js'
import { usePagination } from '@/utils/pagination'
import { Order, OrderInput } from '@/api/interfaces'
import { useAuthGetters, useNotify } from '@/store'
import OrderTable from '@/components/order/order-table.cmp.vue'
import OrderDialog from '@/components/order/order-dialog.vue'
import CommonAutocomplete from '@/components/common/common-autocomplete.vue'
import { Rights } from '@/api/rights'
import { useOrderRepository } from '@/api/repository/order-repository'
import { debounce } from 'lodash-es'
import { useProjectRepository } from '@/api/repository/project-repository'
import { usePermissions } from '@/composables/permissions'

export default defineComponent({
  name: 'order-overview',
  components: {
    OrderTable,
    OrderDialog,
    CommonAutocomplete,
  },
  setup(props, { root }) {
    const { vuetifyTableOptions, paginationParams } = usePagination()
    vuetifyTableOptions.value.sortBy = ['id']
    vuetifyTableOptions.value.sortDesc = [true]

    const { currentUser } = useAuthGetters()
    const { addNotification } = useNotify()
    const orderRepository = useOrderRepository()
    const { getAll, data: orders, isLoading, total } = orderRepository.useGetAll()
    const filters: { userId?: number; projectIds?: number; dateFrom?: string; dateTo?: string } = reactive({
      userId: undefined,
      projectIds: undefined,
      dateFrom: undefined,
      dateTo: undefined,
    })

    const activeYear = ref(new Date().getFullYear())

    setYear(activeYear.value)
    const years = computed(() => {
      const years: { text: number; value: number }[] = []
      const currentYear = new Date().getFullYear()
      for (let i = 2017; i <= currentYear; i++) {
        years.push({ text: i, value: i })
      }
      return years
    })

    function setYear(year?: number) {
      if (year === null) {
        filters.dateFrom = undefined
        filters.dateTo = undefined
      } else {
        filters.dateFrom = `${year}-01-01`
        filters.dateTo = `${year}-12-31`
      }
    }

    const { hasRight } = useAuthGetters()

    const { canCreateOrder, canUpdateOrder, canDeleteOrder } = usePermissions()

    const editIsActive = ref(false)
    const deleteIsActive = ref(false)
    const orderToEdit = ref<OrderInput>({ ...orderRepository.dummyOrderInput() })
    const orderToDelete = ref<Order | null>(null)

    async function updateOrders() {
      await getAll({ params: { ...paginationParams.value, ...filters } })
    }

    async function onSaveOrder(order: Order) {
      const { save } = orderRepository.useSave()
      await save(order)
      addNotification({ text: root.$t('form.save.success').toString(), type: 'success' })
      updateOrders()
    }

    async function onDeleteOrder(order: Order) {
      const { remove } = orderRepository.useRemove()
      await remove(order.id)
      addNotification({ text: root.$t('form.delete.success').toString(), type: 'success' })
      updateOrders()
    }

    const projectRepository = useProjectRepository()
    const {
      getAll: getAllProjects,
      data: projects,
      paginationResponse: projectPaginationResponse,
    } = projectRepository.useGetAll()
    const projectSearchInput = ref('')
    const projectManagerIds = computed(() =>
      hasRight.value(Rights.ORDER_CREATE_ALL) ? undefined : [currentUser.value.id]
    )
    const debounceProjectSeach = debounce(
      (name) =>
        getAllProjects({
          params: { name, projectManagerIds: projectManagerIds.value, size: 15 },
        }),
      500
    )
    watch(projectSearchInput, (name) => debounceProjectSeach(name))

    watch(activeYear, (activeYear) => {
      setYear(activeYear)
    })

    watch(filters, () => {
      updateOrders()
    })

    const filterPageCounter = ref<number>(1)

    function loadProjects() {
      if (filterPageCounter.value <= projectPaginationResponse.value.totalPages) {
        getAllProjects({ params: { page: filterPageCounter.value++, size: 15 } })
      }
    }

    function resetFilterPageCounter() {
      filterPageCounter.value = 1
    }

    return {
      filters,
      activeYear,
      years,
      orders,
      icons: { mdiPlus, mdiCheck, mdiClose, mdiPencil, mdiDelete },
      vuetifyTableOptions,
      updateOrders,
      isLoading,
      total,
      canCreateOrder,
      editIsActive,
      deleteIsActive,
      orderToEdit,
      orderToDelete,
      onSaveOrder,
      onDeleteOrder,
      canUpdateOrder,
      canDeleteOrder,
      projectSearchInput,
      projects,
      loadProjects,
      resetFilterPageCounter,
    }
  },
})
