
import { Activity, ActivityInput } from '@/api/interfaces'
import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { useGetUsersBasic, UserBasic } from '@/api/users'
import { useValidation, ValidationForm } from '@/utils/validation'
import ActivityForm from '@/components/activity/activity-form.cmp.vue'
import DateInput from '@/components/common/date-input.vue'
import { usePlanRepository } from '@/api/repository/plan-repository'

export default defineComponent({
  name: 'activity-dialog',
  components: {
    ActivityForm,
    DateInput,
  },
  props: {
    item: {
      type: Object as PropType<Activity | ActivityInput>,
      required: true,
    },
  },
  setup(props, { attrs, emit }) {
    const form = ref<ValidationForm | null>(null)
    const formValid = ref(false)
    const itemInternal = ref<Activity | ActivityInput>({ ...props.item })

    const { getUsersBasic, data: usersBasic } = useGetUsersBasic()
    const userSelected = ref<UserBasic | null>()

    const dateTodayString = new Date().toISOString().split('T')[0]

    const planRepository = usePlanRepository()
    const { getAll: getAllPlans, data: plans } = planRepository.useGetAll()

    function clickCancel() {
      emit('input', false)
    }

    function onSave(item: ActivityInput) {
      emit('save', { ...item })
      emit('input', false)
    }

    watch(
      () => props.item,
      (value: Activity | ActivityInput) => {
        itemInternal.value = { ...value }
      }
    )

    watch(
      () => attrs.value,
      (value) => {
        if (value) {
          getUsersBasic({ page: 0, size: 9999 })
          itemInternal.value = { ...props.item }
          // form.value?.resetValidation()
        }
      }
    )

    watch([() => itemInternal.value.userId, () => itemInternal.value.date], (values) => {
      getAllPlans({ params: { userId: values[0], from: values[1], to: values[1], size: 9999 } })
    })

    return {
      form,
      formValid,
      itemInternal,
      usersBasic,
      plans,
      userSelected,
      onSave,
      clickCancel,
      validation: useValidation(),
      dateTodayString,
    }
  },
})
