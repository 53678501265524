import { repository } from '@/plugins/repository'
import { Order, OrderInput } from '@/api/interfaces'

export const useOrderRepository = () => {
  const version = 'v1'
  const resource = 'orders'
  const orderRepository = repository<Order, OrderInput>(version, resource)

  const dummyOrderInput = (): OrderInput => ({
    date: new Date().toISOString().split('T')[0],
    name: '',
    netAmount: 0,
    orderNumber: '',
    projectId: 0,
  })

  const dummyOrder = (): Order => ({
    date: new Date().toISOString().split('T')[0],
    id: 0,
    name: '',
    orderNumber: '',
    projectId: 0,
    projectName: '',
    netAmount: 0,
  })

  return {
    ...orderRepository,
    dummyOrderInput,
    dummyOrder,
  }
}
