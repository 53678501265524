
import { mdiClose, mdiCheck } from '@mdi/js'
import { useFormatPlan } from '@/utils/format/format-plan'
import { Activity, ActivityInput, Plan } from '@/api/interfaces'
import { useValidation, ValidationForm } from '@/utils/validation'
import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { formatStringToNumber } from '@/utils/format/format-string-to-number'

export default defineComponent({
  name: 'activity-form',
  inheritAttrs: false,
  props: {
    item: {
      type: Object as PropType<Activity | ActivityInput>,
      required: true,
    },
    plans: {
      type: Array as PropType<Plan[]>,
      required: true,
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const form = ref<ValidationForm | null>()

    const itemInternal = ref<Activity | ActivityInput>({ ...props.item })

    const availableHours = [0.5, 1, 1.5, 2, 4, 6, 8]

    const { formatPlan } = useFormatPlan()

    async function OnSave() {
      if (!form.value?.validate()) return
      emit('save', { ...itemInternal.value })
    }

    async function onDelete() {
      emit('delete')
    }

    async function onDeleteConfirm() {
      emit('delete:confirm')
    }

    function clickHourChip(hour: number) {
      itemInternal.value.hours = hour
    }

    function onHoursInput(value: string | null) {
      if (value) itemInternal.value.hours = formatStringToNumber(value)
    }

    watch(
      () => props.item,
      (value) => {
        itemInternal.value = value
      },
      { deep: true }
    )

    return {
      form,
      itemInternal,
      availableHours,
      icons: { mdiClose, mdiCheck },
      OnSave,
      onDelete,
      formatPlan,
      onHoursInput,
      clickHourChip,
      onDeleteConfirm,
      ...useValidation(),
      formatStringToNumber,
    }
  },
})
